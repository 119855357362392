import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { Container, LeftContent, RightContent } from './divided-content.styles';

interface DividedContentProps {
  header: string;
  description: string;
  children: React.ReactNode;
}

export const DividedContent = ({
  header,
  description,
  children,
}: DividedContentProps) => {
  return (
    <Container>
      <LeftContent>
        <Typography
          variant="headline8"
          variantColor="var(--color-secondary-50)"
        >
          {header}
        </Typography>
        <Typography variant="body3" variantColor="var(--color-neutral-50)">
          {description}
        </Typography>
      </LeftContent>
      <RightContent>{children}</RightContent>
    </Container>
  );
};

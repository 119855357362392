import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs};
  padding: ${({ theme }) => theme.spacings.s} ${({ theme }) => theme.spacings.m};
  background: ${({ theme }) => theme.colors.secondary[99]};
  border-radius: ${({ theme }) => theme.borders.radius.l};
  border: ${({ theme }) => theme.borders.width.xs2} solid
    ${({ theme }) => theme.colors.secondary[80]};
`;

export const Image = styled.div<{ $imageUrl: string }>`
  width: 160px;
  height: 160px;
  flex-shrink: 0;
  border-radius: ${({ theme }) => theme.borders.radius.circular};
  background-image: url(${({ $imageUrl }) => $imageUrl});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const TextsContainer = styled.div`
  max-width: 584px;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacings.xs3} 0;
  gap: ${({ theme }) => theme.spacings.xs3};
`;

import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export interface TabLabelStyledProps {
  isDisabled?: boolean;
  isSelected?: boolean;
}

export const TabLabelContainer = styled.div<TabLabelStyledProps>((props) => ({
  width: '180px',
  backgroundColor: props.theme.colors.neutral[100],
  color: props.theme.colors.neutral[50],
  display: 'flex',
  alignItems: 'center',
  gap: props.theme.spacings.xs4,
  borderRadius: props.theme.borders.radius.m,
  padding: `${props.theme.spacings.xs3} ${props.theme.spacings.xs1}`,
  border: `1px solid ${props.theme.colors.neutral[90]}`,
  cursor: 'pointer',

  ...(props.isSelected && {
    cursor: 'pointer',
    pointerEvents: 'all',
    backgroundColor: props.theme.colors.secondary[95],
    color: props.theme.colors.primary,
    opacity: 1,
    borderColor: props.theme.colors.primary,
  }),

  ...(props.isDisabled && {
    cursor: 'none',
    pointerEvents: 'all',
    backgroundColor: props.theme.colors.secondary[90],
    opacity: 0.65,
  }),

  [`&:hover`]: {
    color: props.theme.colors.primary,
    borderColor: props.theme.colors.secondary[95],
    backgroundColor: props.theme.colors.secondary[95],
    fill: props.theme.colors.primary,

    [`p`]: {
      color: props.theme.colors.primary,
    },

    [`.tab-tag`]: {
      backgroundColor: props.theme.colors.primary,
      [`p`]: {
        color: props.theme.colors.neutral[100],
      },
    },
  },

  '&:active': {
    borderColor: props.theme.colors.secondary[70],
    backgroundColor: props.theme.colors.secondary[70],
    fill: props.theme.colors.primary,

    p: {
      color: props.theme.colors.neutral[100],
    },
  },
}));

export const TabLabel = styled(Typography)<TabLabelStyledProps>((props) => ({
  color: props.theme.colors.neutral[50],
  fontWeight: 600,

  ...(props.isSelected && {
    color: props.theme.colors.primary,
  }),

  [`&:hover`]: {
    color: props.theme.colors.primary,
  },
}));

export const Counter = styled.div<TabLabelStyledProps>((props) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: '15px',
  height: '15px',
  padding: `0 ${props.theme.spacings.xs4}px`,
  borderRadius: props.theme.borders.radius.xs,
  backgroundColor: props.theme.colors.neutral[50],

  ...(props.isSelected && {
    backgroundColor: props.theme.colors.primary,
  }),
}));

export const CounterLabel = styled(Typography)`
  color: ${(props) => props.theme.colors.neutral[100]};
  font-weight: 600;
  line-height: 12px;
  font-size: 10px;
`;

export const LabelDescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${(props) => props.theme.spacings.xs2}
    ${(props) => props.theme.spacings.xs4};
  flex-grow: 1;

  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
`;

import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs4};

  &.collapsible {
    cursor: pointer;
  }
`;

export const SubRecoursesContainer = styled.div`
  margin-left: ${({ theme }) => theme.spacings.xs4};
  border-left: 1px solid ${({ theme }) => theme.colors.neutral[80]};
`;

export const SubResourceText = styled(Typography)`
  padding: ${({ theme }) => theme.spacings.xs5}
    ${({ theme }) => theme.spacings.xs2};
`;

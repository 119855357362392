import styled from 'styled-components';

export const Container = styled.div`
  width: 85%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.s};
  padding: ${({ theme }) => theme.spacings.s};
  background: ${({ theme }) => theme.colors.neutral[100]};
  border-radius: ${({ theme }) => theme.borders.radius.m};
  border: ${({ theme }) => theme.borders.width.xs2} solid
    ${({ theme }) => theme.colors.neutral[90]};
`;

export const Header = styled.div`
  max-width: 440px;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs5};
`;

export const Content = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs2};
`;

export const ContentItemContainer = styled.div`
  max-width: 320px;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.m};
  padding: ${({ theme }) => theme.spacings.s};
  background: ${({ theme }) => theme.colors.neutral[100]};
  border-radius: ${({ theme }) => theme.borders.radius.s};
  border: ${({ theme }) => theme.borders.width.xs2} solid
    ${({ theme }) => theme.colors.neutral[90]};
`;

export const ContentItem = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: ${({ theme }) => theme.spacings.xs4};
`;

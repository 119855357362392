import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs1};
  background: ${({ theme }) => theme.colors.neutral[100]};
  border-radius: ${({ theme }) => theme.borders.radius.s};
  border: ${({ theme }) => theme.borders.width.xs2} solid
    ${({ theme }) => theme.colors.neutral[90]};
  padding: ${({ theme }) => theme.spacings.xs}
    ${({ theme }) => theme.spacings.s};
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs2};
`;

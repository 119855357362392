import React from 'react';

import { TabItens } from '..';

import * as SC from './styled';
import { TabLabel } from './TabLabel';

export type TabProps = {
  tabItems: TabItens[];
  currentTab: number;
  className?: string;
  handleChange: (index: number) => any;
};

export const TabTertiary = ({
  tabItems,
  currentTab,
  className,
  handleChange,
}: TabProps) => {
  return (
    <SC.Container className={`vertical-tab-container ${className}`}>
      <SC.TabsGroup className="tabs-group">
        {tabItems.map(
          (tab, index) => (
            <button
              type="button"
              key={typeof tab.label === 'string' ? tab.label : index}
              onClick={() => !tab.disabled && handleChange(index)}
            >
              <TabLabel
                label={tab.label}
                tag={tab.counterLabel}
                icon={tab.iconLabel}
                isDisabled={tab.disabled}
                isSelected={index == currentTab}
              />
            </button>
          ),
          [],
        )}
      </SC.TabsGroup>

      {tabItems[currentTab]?.component}
    </SC.Container>
  );
};

import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  gap: ${({ theme }) => theme.spacings.l};
`;

export const Content = styled.div`
  display: flex;
  flex-grow: 1;
  gap: ${({ theme }) => theme.spacings.xs};
  margin-top: ${({ theme }) => theme.spacings.l};
`;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.spacings.xs5};
`;

export const ValueWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs4};
  align-items: center;
`;

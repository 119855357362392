import {
  segmentEventTracking,
  segmentPageTracking,
} from '@flash-tecnologia/hros-web-utility';

const MODULE_NAME = 'acquisition';
const BUSINESS_UNIT_NAME = 'platform';

export const trackEvent = (name: string, params?: Record<string, string>) => {
  segmentEventTracking({
    name,
    params,
    module: MODULE_NAME,
    businessUnit: BUSINESS_UNIT_NAME,
  });
};

export const trackPage = (name: string, params?: Record<string, string>) => {
  segmentPageTracking({
    name,
    params,
    module: MODULE_NAME,
    businessUnit: BUSINESS_UNIT_NAME,
  });
};

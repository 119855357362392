import { Tab, Tabs } from '@mui/material';
import styled from 'styled-components';

export const TabsGroup = styled(Tabs)`
  min-height: 40px;
  margin-bottom: ${(props) => props.theme.spacings.xs2};

  span.MuiTabs-indicator {
    background-color: ${(props) => props.theme.colors.primary};
  }
`;

export const TabItem = styled(Tab)`
  background-color: ${(props) => props.theme.colors.neutral[100]};
  padding: ${(props) =>
    `${props.theme.spacings.xs4} ${props.theme.spacings.xs2}`};
  text-transform: none;
  min-height: 40px;

  &.Mui-selected {
    color: ${(props) => props.theme.colors.primary};
    font-weight: 700;
  }

  &.Mui-disabled {
    color: ${(props) => props.theme.colors.neutral[70]};
  }
`;

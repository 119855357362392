import {
  Icons,
  LinkButton,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { Container, Image, TextsContainer } from './consultant-contact.styles';
import { useSalesContactModal } from '@/context/sales-contact-modal';
import { useTranslation } from 'react-i18next';
import { trackEvent } from '@/utils/tracking';

interface ConsultantContactProps {
  marketPageId: string;
}

export const ConsultantContact = ({ marketPageId }: ConsultantContactProps) => {
  const [t] = useTranslation('translations', {
    keyPrefix: 'consultantContact',
  });
  const { openModal: openSalesModal } = useSalesContactModal();

  const onSalesContactRequest = () => {
    trackEvent('acquisition_consultant_clicked', {
      origin: 'consultant_banner',
    });
    openSalesModal(marketPageId);
  };

  return (
    <Container>
      {/* TODO: Replace for image link */}
      <Image $imageUrl="https://images.flashapp.com.br/flash-os/home_image.png" />
      <TextsContainer>
        <Typography variant="headline8" variantColor="var(--color-neutral-20)">
          {t('headline')}
        </Typography>
        <Typography
          variant="body3"
          variantColor="var(--color-neutral-50)"
          style={{ flexGrow: 1 }}
        >
          {t('text')}
        </Typography>
        <LinkButton variant="default" onClick={onSalesContactRequest}>
          {t('callToAction')} <Icons name="IconArrowRight" />
        </LinkButton>
      </TextsContainer>
    </Container>
  );
};

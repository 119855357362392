import { trpc } from '@/api/client';
import { getAccessTokenPayloadSync } from '@flash-tecnologia/hros-web-utility';

export const useExecuteDeal = () => {
  const { mutateAsync, isLoading, error } = trpc.executeDeal.useMutation();

  const executeDeal = async ({
    dealId,
    companyId,
  }: {
    companyId: string;
    dealId: string;
  }) => {
    const { employeeId } = getAccessTokenPayloadSync();
    return mutateAsync({ companyId, employeeId, dealId });
  };

  return { executeDeal, isLoading, error };
};

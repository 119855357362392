import {
  Button,
  Modal,
  ShapeIcon,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { ModalContent } from './success-step.styles';
import { useTranslation } from 'react-i18next';

interface SuccessStepProps {
  email: string;
  phone: string;
  onClose: () => void;
}

export const SuccessStep = ({ email, phone, onClose }: SuccessStepProps) => {
  const [t] = useTranslation('translations', {
    keyPrefix: 'salesContactModal',
  });
  return (
    <>
      <ModalContent>
        <ShapeIcon
          variant="default"
          name="IconCheck"
          color="var(--color-secondary-50)"
          strokeWidth="1.5px"
          size={64}
        />
        <Typography variant="headline6" variantColor="var(--color-neutral-20)">
          {t('successTitle')}
        </Typography>
        <Typography variant="body4" variantColor="var(--color-neutral-50)">
          Em breve um de nossos especialistas entrará em contato pelo e-mail{' '}
          {email} ou pelo telefone {phone}.
          <br />
          {t('contactWindowText')}
        </Typography>
      </ModalContent>
      <Modal.Footer>
        <Button
          size="large"
          variant="primary"
          onClick={onClose}
          style={{ margin: '0 auto' }}
        >
          {t('finishButton')}
        </Button>
      </Modal.Footer>
    </>
  );
};

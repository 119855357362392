import {
  Button,
  IconTypes,
  Icons,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { Container, Image, Section, Topic } from './offer-card.styles';

interface OfferCardProps {
  imageUrl: string;
  title: string;
  text: string;
  topics: {
    icon: IconTypes;
    text: string;
  }[];
  actionText: string;
  onClick: () => void;
}

export const OfferCard = ({
  title,
  text,
  topics,
  actionText,
  onClick,
  imageUrl,
}: OfferCardProps) => {
  return (
    <Container>
      <Image src={imageUrl} alt="foto promocional da oferta" />
      <Section>
        <Typography
          variant="headline8"
          variantColor="var(--color-secondary-50)"
        >
          {title}
        </Typography>
        <Typography variant="body3" variantColor="var(--color-neutral-50)">
          {text}
        </Typography>
      </Section>
      <Section style={{ flexGrow: 1 }}>
        {topics.map(({ icon, text }, i) => (
          <Topic key={i}>
            <Icons
              name={icon}
              color="var(--color-primary)"
              fill="none"
              size={16}
            />
            <Typography variant="body4" variantColor="var(--color-neutral-50)">
              {text}
            </Typography>
          </Topic>
        ))}
      </Section>
      <Section>
        <Button size="small" variant="secondary" onClick={onClick}>
          {actionText} <Icons name="IconArrowRight" />
        </Button>
      </Section>
    </Container>
  );
};

import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSalesContactModal } from '@/context/sales-contact-modal';
import {
  Button,
  Icons,
  LinkButton,
  Modal,
  TextField,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import {
  getAuthenticatedUser,
  useSelectedCompany,
} from '@flash-tecnologia/hros-web-utility';
import {
  HelperTextContainer,
  InputsContainer,
  RequiredText,
} from './sales-contact.styles';
import { SuccessStep } from './SuccessStep/success-step';
import { dispatchToast } from '@/utils';
import { useTranslation } from 'react-i18next';
import { trackEvent } from '@/utils/tracking';
import { useCreateFarmingContact } from './hooks/use-create-farming-contact';

const validationSchema = yup.object({
  name: yup.string(),
  email: yup.string().required('Por favor, digite o e-mail'),
  phoneNumber: yup
    .string()
    .required('Por favor, digite o número de celular')
    .min(16, 'O número deve conter 11 dígitos'),
});

export const SalesContactModal = () => {
  const [t] = useTranslation('translations', {
    keyPrefix: 'salesContactModal',
  });
  const { isOpen, closeModal, offerInfo } = useSalesContactModal();
  const { createFarmingContact } = useCreateFarmingContact();
  const { selectedCompany } = useSelectedCompany();
  const [showSuccessStep, setShowSuccessStep] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const form = useFormik({
    initialValues: {
      name: '',
      email: '',
      phoneNumber: '',
    },
    initialErrors: {},
    validationSchema,
    onSubmit: async (values) => {
      try {
        trackEvent('acquisition_consultant_modal_submit_clicked');
        setIsLoading(true);

        if (!offerInfo?.offerId) {
          return;
        }

        await createFarmingContact({
          offerId: offerInfo.offerId,
          companyId: selectedCompany.id,
          contact: {
            name: values.name,
            email: values.email,
            phoneNumber: values.phoneNumber,
          },
        });
        setShowSuccessStep(true);
      } catch {
        dispatchToast({
          type: 'error',
          content: 'Ocorreu um erro. Por favor, tente novamente.',
        });
      } finally {
        setIsLoading(false);
      }
    },
  });

  useEffect(() => {
    async function getUser() {
      // TODO: get employee from company?
      const authenticatedUser = await getAuthenticatedUser();
      if (!authenticatedUser) return;

      // Workaround for imask phone input and formik to work as intended
      form.resetForm();
      form.setValues({
        name: authenticatedUser?.name || '',
        email: authenticatedUser.email,
        phoneNumber: authenticatedUser.phoneNumber.substring(
          authenticatedUser.phoneNumber.length - 11,
        ),
      });
      form.validateForm();
    }

    if (isOpen) {
      getUser();
    }
  }, [isOpen]);

  const onClose = () => {
    if (!showSuccessStep) {
      trackEvent('acquisition_consultant_modal_cancel_clicked');
    }

    closeModal();
    setShowSuccessStep(false);
  };

  return (
    <Modal.Root
      open={isOpen}
      onClose={onClose}
      size={showSuccessStep ? 'xs' : 'sm'}
    >
      <>
        {showSuccessStep && (
          <SuccessStep
            email={form.values.email}
            phone={form.values.phoneNumber}
            onClose={onClose}
          />
        )}
        {!showSuccessStep && (
          <>
            <Modal.Header
              title="Falar com consultor"
              description="Tire suas dúvidas diretamente com o nosso time de especialistas. Preencha o formulário abaixo para que o seu atendimento seja mais ágil e focado em suas necessidades."
            />
            <Modal.Content>
              <RequiredText
                variant="body3"
                variantColor="var(--color-neutral-30)"
              >
                <Typography
                  variant="body3"
                  tag="span"
                  variantColor="var(--color-feedback-error-50)"
                >
                  *
                </Typography>{' '}
                campo obrigatório
              </RequiredText>
              <InputsContainer>
                <TextField
                  label="Nome Completo"
                  id="name"
                  name="name"
                  fullWidth
                  value={form.values.name}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                />
                <div>
                  <TextField
                    required
                    label="E-mail corporativo"
                    id="email"
                    name="email"
                    fullWidth
                    value={form.values.email}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    error={form.touched.email && Boolean(form.errors.email)}
                  />
                  {form.touched.email && Boolean(form.errors.email) && (
                    <HelperTextContainer>
                      <Icons
                        size={15}
                        name="IconAlertCircle"
                        fill="none"
                        color="var(--color-feedback-error-dark1)"
                      />
                      <Typography
                        variant="body4"
                        variantColor="var(--color-feedback-error-dark1)"
                      >
                        {form.errors.email}
                      </Typography>
                    </HelperTextContainer>
                  )}
                </div>
                <div>
                  <TextField
                    required
                    label="Celular"
                    id="phoneNumber"
                    name="phoneNumber"
                    fullWidth
                    value={form.values.phoneNumber}
                    imaskProps={{ mask: '(00) 0 0000-0000' }}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    error={
                      form.touched.phoneNumber &&
                      Boolean(form.errors.phoneNumber)
                    }
                  />
                  {form.touched.phoneNumber &&
                    Boolean(form.errors.phoneNumber) && (
                      <HelperTextContainer>
                        <Icons
                          size={15}
                          name="IconAlertCircle"
                          fill="none"
                          color="var(--color-feedback-error-dark1)"
                        />
                        <Typography
                          variant="body4"
                          variantColor="var(--color-feedback-error-dark1)"
                        >
                          {form.errors.phoneNumber}
                        </Typography>
                      </HelperTextContainer>
                    )}
                </div>
              </InputsContainer>
            </Modal.Content>
            <Modal.Footer>
              <LinkButton
                variant="default"
                onClick={onClose}
                disabled={isLoading}
                style={{ alignSelf: 'center' }}
              >
                {t('cancelButton')}
              </LinkButton>
              <Button
                size="large"
                variant="primary"
                onClick={form.submitForm}
                disabled={!form.isValid || isLoading}
                loading={isLoading}
                style={{ width: 200 }}
              >
                {t('confirmButton')} <Icons name="IconSend" />
              </Button>
            </Modal.Footer>
          </>
        )}
      </>
    </Modal.Root>
  );
};

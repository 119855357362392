import {
  IconsProps,
  ShapeIcon,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { Container, ContentWrapper } from './info-block.styles';

interface InformationContainerProps {
  icon: IconsProps['name'];
  title: string;
  description?: string;
  items: Array<{ name: string; description: string }>;
}

export const InfoBlock = ({
  icon,
  title,
  description,
  items,
}: InformationContainerProps) => {
  return (
    <Container>
      <ShapeIcon
        name={icon}
        variant="default"
        color="var(--color-primary)"
        size={40}
        style={{ flexShrink: 0 }}
      />
      <ContentWrapper>
        <div>
          <Typography
            variant="headline9"
            variantColor="var(--color-secondary-50)"
          >
            {title}
          </Typography>
          {description && (
            <Typography variant="body4" variantColor="var(--color-neutral-50)">
              {description}
            </Typography>
          )}
        </div>
        {items.map((item, index) => (
          <div key={item.name + index}>
            <Typography
              variant="body4"
              weight={700}
              variantColor="var(--color-neutral-40)"
            >
              {item.name}
            </Typography>
            <Typography variant="body4" variantColor="var(--color-neutral-50)">
              {item.description}
            </Typography>
          </div>
        ))}
      </ContentWrapper>
    </Container>
  );
};

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
`;

export const TabsGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacings.xs3};
  width: 450px;
  min-width: 200px;
`;

import styled from 'styled-components';

export const Container = styled.div`
  width: 264px;
  height: 280px;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacings.s};
  gap: ${({ theme }) => theme.spacings.xs3};
  border-radius: ${({ theme }) => theme.borders.radius.s};
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

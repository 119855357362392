import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export interface TabLabelStyledProps {
  isDisabled?: boolean;
  isSelected?: boolean;
}

export const TabLabelContainer = styled.div<TabLabelStyledProps>`
  cursor: ${(props) => (!props.isDisabled ? 'pointer' : 'not-allowed')};
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'all')};
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacings.xs4};
  opacity: ${(props) => (props.isDisabled ? 0.65 : 1)};

  &:hover {
    color: ${(props) => props.theme.colors.primary};

    .tab-counter {
      background-color: ${(props) => props.theme.colors.primary};
    }
  }
`;

export const TabLabel = styled(Typography)<TabLabelStyledProps>`
  color: ${(props) =>
    props.isSelected
      ? props.theme.colors.primary
      : props.theme.colors.neutral[50]};

  font-weight: ${(props) => (props.isSelected ? '700' : '600')} !important;

  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const Counter = styled.div<TabLabelStyledProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  padding: 0 ${(props) => props.theme.spacings.xs4};
  border-radius: ${(props) => props.theme.borders.radius.xs};
  background-color: ${(props) =>
    props.isSelected
      ? props.theme.colors.primary
      : props.theme.colors.neutral[50]};
`;

export const CounterLabel = styled(Typography)<TabLabelStyledProps>`
  color: ${(props) => props.theme.colors.neutral[100]};
  font-weight: ${(props) => (props.isSelected ? '700' : '600')} !important;
  line-height: 12px;
  font-size: 10px;
`;

export const LabelDescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacings.xs4};

  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
`;

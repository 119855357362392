type CurrencyOptions = {
  locale?: string;
  currency?: string;
};

export const formatCurrency = (
  value: number,
  options: Partial<CurrencyOptions> = {},
) => {
  const { locale = 'pt-BR', currency = 'BRL' } = options;

  const currencyFormatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  });

  return currencyFormatter.format(value);
};

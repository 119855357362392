import {
  Icons,
  IconsProps,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import {
  Container,
  SubRecoursesContainer,
  SubResourceText,
} from './resource-item.styles';
import { useState } from 'react';
import cx from 'classnames';
import { Collapse } from '@mui/material';

type ResourceProps = {
  icon: string;
  name: string;
  subResources: string[];
};

export const ResourceItem = ({ icon, name, subResources }: ResourceProps) => {
  const [open, setOpen] = useState(subResources.length > 0);
  const hasSubItems = subResources.length > 0;

  const toggleItem = () => {
    if (hasSubItems) setOpen(!open);
  };

  return (
    <>
      <Container
        className={cx({ collapsible: hasSubItems })}
        onClick={toggleItem}
      >
        <Icons
          name={icon as IconsProps['name']}
          size={16}
          height={22}
          color="var(--color-primary)"
          style={{ flexShrink: 0 }}
        />
        <Typography
          variant="body3"
          weight={700}
          variantColor="var(--color-neutral-30)"
          style={{ flexGrow: 1 }}
        >
          {name}
        </Typography>
        {hasSubItems && (
          <Icons
            name={open ? 'IconChevronUp' : 'IconChevronDown'}
            size={16}
            height={22}
          />
        )}
      </Container>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <SubRecoursesContainer>
          {subResources.map((subResource, i) => (
            <SubResourceText
              key={i}
              variant="body4"
              variantColor="var(--color-neutral-30)"
              weight={600}
            >
              {subResource}
            </SubResourceText>
          ))}
        </SubRecoursesContainer>
      </Collapse>
    </>
  );
};

import { Price } from '@/components/Price';
import { Button } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

interface FeaturedPlanProp {
  $isFeaturedPlan?: boolean;
}

export const Container = styled.div<FeaturedPlanProp>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.borders.radius.s};
  border: ${({ theme }) => theme.borders.width.xs2} solid
    ${({ theme }) => theme.colors.neutral[90]};
  overflow: auto;

  &.featured-plan {
    // TODO: move to DS
    // Color not implemented in DS
    // Glows/Pink/Level 3
    box-shadow: 0px 8px 32px 0px #fe2b8f29;
    border: ${({ theme }) => theme.borders.width.xs2} solid
      ${({ theme }) => theme.colors.secondary[70]};
  }
`;

export const TagSlot = styled.div<FeaturedPlanProp>`
  width: 100%;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacings.xs4};

  &.featured-plan {
    background: ${({ theme }) => theme.colors.secondary[95]};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 ${({ theme }) => theme.spacings.xs};
`;

export const Description = styled.div`
  min-height: 214px;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs3};
  padding: ${({ theme }) => theme.spacings.xs} 0;
`;

export const ButtonsContainer = styled.div`
  height: 144px;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs};
  padding: ${({ theme }) => theme.spacings.xs} 0;
`;

export const ResourcesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: ${({ theme }) => theme.spacings.xs};
  padding: ${({ theme }) => theme.spacings.xs} 0;
`;

export const ResourceList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs4};
`;

export const ResourceKnowMoreButton = styled(Button)`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacings.m};
`;

export const PriceWithPadding = styled(Price)`
  height: 108px;
  padding: ${({ theme }) => theme.spacings.xs} 0;
`;

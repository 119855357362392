import {
  IconButton,
  Skeleton,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { Container, TitleContainer } from './offers-comparative.styles';
import { OfferCard } from './components/OfferCard';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { useTranslation } from 'react-i18next';
import { useGetMarketPage } from '@/shared/hooks';
import { useEffect } from 'react';
import { dispatchToast } from '@/utils';
import { useNavigate } from 'react-router-dom';

interface OffersComparativeProps {
  marketPageId: string;
}

export const OffersComparative = ({ marketPageId }: OffersComparativeProps) => {
  const navigate = useNavigate();
  const [t] = useTranslation('translations', {
    keyPrefix: 'acquisition',
  });
  const { marketPage, isLoading, error } = useGetMarketPage(marketPageId);

  useEffect(() => {
    if (error) {
      dispatchToast({ type: 'error', content: 'Página não encontrada.' });
      navigate('/home');
    }
  }, [error, navigate]);

  if (isLoading || !marketPage) {
    return <Skeleton variant="rounded" width="100%" height="900px" />;
  }

  const isMoreThanThreeOffers = marketPage.offers.length > 3;

  return (
    <Container>
      <TitleContainer>
        <Typography variant="headline6" style={{ flexGrow: 1 }}>
          {t('compareSection.title')}
        </Typography>
        {isMoreThanThreeOffers && (
          <>
            <IconButton
              className="swiper-prev-button"
              variant="line"
              size="small"
              icon="IconChevronLeft"
            />
            <IconButton
              className="swiper-next-button"
              variant="line"
              size="small"
              icon="IconChevronRight"
            />
          </>
        )}
      </TitleContainer>

      <Swiper
        className="plans-slider"
        navigation={{
          prevEl: '.swiper-prev-button',
          nextEl: '.swiper-next-button',
        }}
        slidesPerView="auto"
        spaceBetween={24}
        modules={[Navigation]}
      >
        {marketPage.offers.map((offer, i) => (
          <SwiperSlide
            key={'plan-slide-' + i}
            style={{ width: 386, height: 'auto' }}
          >
            <OfferCard marketPageId={marketPageId} offer={offer} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  );
};

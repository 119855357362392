import {
  // IconButton,
  IconsProps,
  Skeleton,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from 'react-i18next';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Container,
  TitleContainer,
  IconButton,
} from './commercial-contents-section.styles';
import { Card } from './components/Card';
import { useGetMarketPage } from '@/shared/hooks';

interface Props {
  marketPageId: string;
}

export const CommercialContentsSection = ({ marketPageId }: Props) => {
  const [t] = useTranslation('translations', {
    keyPrefix: 'acquisition',
  });
  const { marketPage, isLoading } = useGetMarketPage(marketPageId);

  if (isLoading) {
    return <Skeleton variant="rounded" width="100%" height="370px" />;
  }

  if (!marketPage || marketPage.commercialContents.length === 0) {
    return null;
  }

  return (
    <Container>
      <TitleContainer>
        <Typography variant="headline6" style={{ flexGrow: 1 }}>
          {t('commercialContentSection.title')}
        </Typography>
        <IconButton
          className="swiper-prev-button"
          variant="line"
          size="small"
          icon="IconChevronLeft"
        />
        <IconButton
          className="swiper-next-button"
          variant="line"
          size="small"
          icon="IconChevronRight"
        />
      </TitleContainer>
      <Swiper
        watchOverflow
        modules={[Navigation]}
        navigation={{
          prevEl: '.swiper-prev-button',
          nextEl: '.swiper-next-button',
        }}
        slidesPerView="auto"
        spaceBetween={24}
      >
        {marketPage.commercialContents.map((commercialContent, i) => (
          <SwiperSlide style={{ width: 264, height: 280 }} key={i}>
            <Card
              icon={commercialContent.icon as IconsProps['name']}
              headline={commercialContent.title}
              description={commercialContent.description}
              tag={commercialContent.badgeText}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  );
};

export function formatRegistrationNumber(
  registrationNumber: string,
  format = 'CNPJ',
): string {
  if (format === 'CNPJ') {
    const masked = registrationNumber
      .replace(/\D+/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
    return masked;
  }

  return registrationNumber;
}

import { useEffect, useState } from 'react';
import { BaseModal } from '../BaseModal';
import { SpacedContainer, StyledPDFViewer } from './contract-modal.styles';
import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { useGetContractSignedUrl } from '@/pages/Acquisition/Contract/hooks';

type ContractModalProps = {
  open: boolean;
  contractId: string;
  onClose: () => void;
};

export const ContractModal = ({
  open,
  contractId,
  onClose,
}: ContractModalProps) => {
  const { getContractSignedUrl } = useGetContractSignedUrl();

  const [loading, setLoading] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');

  useEffect(() => {
    async function getPdf() {
      setLoading(true);
      const contract = await getContractSignedUrl({ contractId });
      setPdfUrl(contract.url);
      setLoading(false);
    }

    if (contractId) getPdf();
  }, [contractId]);

  return (
    <BaseModal
      open={open}
      onClose={onClose}
      onNext={onClose}
      onNextText="Fechar"
      loading={loading}
    >
      <SpacedContainer>
        <Typography variant="headline8">
          Contrato de Prestação de Serviços
        </Typography>
        <Typography variant="body4" variantColor="var(--color-neutral-40)">
          Para finalizar sua contratação, você deve ler atentamente e aceitar as
          condições comerciais do Contrato de Prestação de Serviços da Flash.
        </Typography>
      </SpacedContainer>
      <Typography
        variant="body4"
        weight={700}
        variantColor="var(--color-neutral-40)"
      >
        Leia abaixo
      </Typography>
      <StyledPDFViewer
        width="100%"
        height="400px"
        src={pdfUrl}
        options={{ showToolbar: true, fit: 'height', page: 1 }}
      />
    </BaseModal>
  );
};

import { PageContainer as BasePageContainer } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const PageContainer = styled(BasePageContainer)`
  && {
    flex-grow: 0;
  }
`;

export const SectionBlocks = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs};
  flex-wrap: wrap;

  @media screen and (max-width: 414px) {
    flex-direction: column;
  }
`;

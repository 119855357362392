import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  gap: ${({ theme }) => theme.spacings.l};
  padding-bottom: 12px;

  & .plans-slider {
    padding-bottom: ${({ theme }) => theme.spacings.xs};
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-grow: 1;
  gap: ${({ theme }) => theme.spacings.xs2};
  margin-bottom: ${({ theme }) => theme.spacings.m};
`;

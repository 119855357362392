import styled from 'styled-components';
import { Tag as BaseTag } from '@flash-tecnologia/hros-web-ui-v2';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-grow: 1;
  gap: ${({ theme }) => theme.spacings.xs4};
`;

export const Text = styled.span`
  flex-grow: 1;
  text-align: left;
`;

export const Tag = styled(BaseTag)`
  height: 20px;
  padding: ${({ theme }) => theme.spacings.xs5 + ' ' + theme.spacings.xs4};
  background: ${({ theme }) => theme.colors.secondary[99]};
  align-self: center;
`;

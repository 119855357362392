import {
  GenericProfilePicture,
  ShapeIcon,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import {
  Company,
  Container,
  Content,
  IconContainer,
  Product,
} from './review-step.style';
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility';
import { DividedContent } from '../DividedContent';
import { useTranslation } from 'react-i18next';
import { formatRegistrationNumber } from '@/utils';

interface ReviewStepProps {
  planNames: string[];
}

export const ReviewStep = ({ planNames }: ReviewStepProps) => {
  const [t] = useTranslation('translations', {
    keyPrefix: 'contract.reviewStep',
  });
  const { selectedCompany } = useSelectedCompany();

  return (
    <DividedContent header={t('title')} description={t('productText')}>
      <Container>
        <Typography variant="headline8" variantColor="var(--color-neutral-30)">
          {t('resumeText')}
        </Typography>
        <Content>
          <Typography
            variant="headline9"
            variantColor="var(--color-secondary-50)"
          >
            {t('companyText')}
          </Typography>
          <Company>
            <IconContainer>
              <GenericProfilePicture name={selectedCompany.name} size={40} />
            </IconContainer>
            <div>
              <Typography
                variant="body3"
                variantColor="var(--color-neutral-30)"
                weight={700}
              >
                {selectedCompany.name}
              </Typography>
              <Typography
                variant="body4"
                variantColor="var(--color-neutral-40)"
              >
                CNPJ{' '}
                {formatRegistrationNumber(selectedCompany.registrationNumber)}
              </Typography>
            </div>
          </Company>
        </Content>
        <Content>
          <Typography
            variant="headline9"
            variantColor="var(--color-secondary-50)"
          >
            {t('productSelectedText')}
          </Typography>
          {planNames.map((planName) => (
            <Product>
              <ShapeIcon
                name="IconShoppingBag"
                variant="default"
                size={48}
                color="var(--color-secondary-50)"
                style={{ flexShrink: 0 }}
              />
              <Typography
                variant="body3"
                variantColor="var(--color-neutral-30)"
                weight={700}
              >
                {planName}
              </Typography>
            </Product>
          ))}
        </Content>
      </Container>
    </DividedContent>
  );
};

import { Container, ContractItemContainer } from './contract-selector.styles';
import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
interface ContractSelectorProps {
  onSelectContract: (index: number) => void;
  contracts: {
    id: string;
    title: string;
    isSelected: boolean;
  }[];
}

interface ContractItemProps {
  isSelected: boolean;
  title: string;
  onSelectContract: (index: number) => void;
  index: number;
}
const ContractItem = (props: ContractItemProps) => (
  <ContractItemContainer
    isSelected={props.isSelected}
    onClick={() => props.onSelectContract(props.index)}
  >
    <Typography
      variant="headline9"
      variantColor={
        props.isSelected
          ? 'var(--color-secondary-50)'
          : 'var(--color-neutral-40)'
      }
    >
      {props.title}
    </Typography>
  </ContractItemContainer>
);

export const ContractSelector = (props: ContractSelectorProps) => {
  return (
    <Container>
      {props.contracts.map((contract, index) => (
        <div key={contract.title}>
          <ContractItem
            index={index}
            isSelected={contract.isSelected}
            title={contract.title}
            onSelectContract={props.onSelectContract}
          />
        </div>
      ))}
    </Container>
  );
};

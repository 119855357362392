import { Tag, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { formatCurrency } from '@/utils';
import { Container, ValueWrapper } from './price.styles';

export interface PriceProps {
  value: number;
  valueWithDiscount?: number;
  unit?: string;
  className?: string;
}

export const Price = ({
  value,
  valueWithDiscount,
  unit,
  className,
}: PriceProps) => {
  const hasDiscount = !!valueWithDiscount;
  const discountPercentage = hasDiscount
    ? ((1 - valueWithDiscount / value) * 100).toFixed(2)
    : 0;

  const currentValue = hasDiscount ? valueWithDiscount : value;

  if (currentValue === 0) {
    return (
      <Container className={className}>
        <Typography variant="headline6" variantColor="var(--color-neutral-30)">
          Gratuito
        </Typography>
      </Container>
    );
  }

  return (
    <Container className={className}>
      {hasDiscount && (
        <Typography variant="caption" variantColor="var(--color-neutral-40)">
          De <s>{formatCurrency(value)}</s> por
        </Typography>
      )}
      {!hasDiscount && (
        <Typography variant="body3" variantColor="var(--color-neutral-40)">
          por apenas
        </Typography>
      )}
      <ValueWrapper>
        <Typography variant="headline6" variantColor="var(--color-neutral-30)">
          {formatCurrency(currentValue)}{' '}
          <Typography
            tag="span"
            variant="body3"
            variantColor="var(--color-neutral-50)"
          >
            {unit && `/${unit}`}
          </Typography>
        </Typography>
        {hasDiscount && (
          <Typography tag="span" variant="body3">
            <Tag variant="pink" disabled>
              -{discountPercentage}%
            </Tag>
          </Typography>
        )}
      </ValueWrapper>
    </Container>
  );
};

import styled from 'styled-components';
import { Button as BaseButton } from '@flash-tecnologia/hros-web-ui-v2';
import { Price as BasePrice } from '@/components';

export const Container = styled.div`
  max-height: 500px;
  height: 100%;
  width: 100%;
  display: flex;
  gap: ${({ theme }) => theme.spacings.l};
`;

export const Image = styled.div<{ $imageUrl: string }>`
  height: 495px;
  width: 480px;
  flex-shrink: 0;
  border-radius: ${({ theme }) => theme.borders.radius.xl2};
  background-image: url(${({ $imageUrl }) => $imageUrl});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 1512px) {
    height: 400px;
    width: 388px;
  }

  @media (max-width: 1110px) {
    display: none;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  gap: ${({ theme }) => theme.spacings.m};
`;

export const TextsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacings.xs2};
`;

export const ButtonsContainer = styled.div<{ $fullWidth?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacings.xs};
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : '50%')};
`;

export const Button = styled(BaseButton)`
  flex: 1;
  min-width: 280px;

  &.disable-grow {
    flex-grow: 0;
  }
`;

export const PriceWithBorder = styled(BasePrice)`
  padding: ${({ theme }) => theme.spacings.xs2};
  border: 1px solid ${({ theme }) => theme.colors.neutral[80]};
  border-radius: ${({ theme }) => theme.borders.radius.m};
`;

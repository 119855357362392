import styled from 'styled-components';
import { IconButton as BaseIconButton } from '@flash-tecnologia/hros-web-ui-v2';

export const Container = styled.div`
  max-height: 500px;
  height: 100%;
  width: 100%;
  gap: ${({ theme }) => theme.spacings.l};
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-grow: 1;
  gap: ${({ theme }) => theme.spacings.xs2};
  margin-bottom: 40px;
`;

export const IconButton = styled(BaseIconButton)`
  &.MuiButtonBase-root:disabled {
    display: none;
  }
`;

import styled from 'styled-components';

export const CommercialConditionsSummary = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.s};
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs4};
  margin-bottom: ${({ theme }) => theme.spacings.xs2};
`;

export const CommercialConditionsContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.s};
  align-items: flex-start;

  & > div {
    flex-basis: 100%;
  }
`;

export const ContractItem = styled.div`
  display: flex;
  align-items: center;
`;

import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import Root from './root.component';
import { trpc, trpcClient, queryClient } from './api/client';
import { QueryClientProvider } from '@tanstack/react-query';
import { RecommendationsSection } from './components/RecommendationsSection';
import { BrowserRouter } from 'react-router-dom';
import { useSession } from './shared/hooks';
import { FlagsProvider } from '@flash-tecnologia/feature-flags';
import { env } from './lib/env';

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
});

export const { bootstrap, mount, unmount } = lifecycles;

function OffersRecommendationSection() {
  const { companyId, userId, economicGroupId } = useSession();
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <FlagsProvider
          appName="hros-web-acquisition"
          url={env.UNLEASH_PROXY_URL}
          token={env.UNLEASH_PROXY_KEY}
          refreshIntervalSeconds={1800}
          initialContext={{
            userId,
            properties: {
              economicGroupId,
              companyId,
              employeeId: userId,
            },
          }}
        >
          <BrowserRouter>
            <RecommendationsSection />
          </BrowserRouter>
        </FlagsProvider>
      </QueryClientProvider>
    </trpc.Provider>
  );
}

export { OffersRecommendationSection };

import styled from 'styled-components';

export const Container = styled.div`
  max-width: 344px;
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.borders.radius.s};
  background: ${({ theme }) => theme.colors.secondary[99]};
  gap: ${({ theme }) => theme.spacings.xs};
  padding-bottom: ${({ theme }) => theme.spacings.xs};
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacings.xs3};
  padding: 0 ${({ theme }) => theme.spacings.xs};
`;

export const Image = styled.img`
  height: 160px;
`;

export const Topic = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs3};
`;

import styled from 'styled-components';
import { Typography } from '@flash-tecnologia/hros-web-ui-v2';

export const RequiredText = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacings.xs2};
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs2};
`;

export const HelperTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs5};
  padding-left: ${({ theme }) => theme.spacings.xs2};
`;

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const TabsGroup = styled.div`
  width: auto;
  display: flex;
  gap: ${(props) => props.theme.spacings.xs4};
  padding: ${(props) => props.theme.spacings.xs4};
  border-radius: ${(props) => props.theme.borders.radius.l};
  border: ${(props) => props.theme.borders.width.xs2} solid
    ${(props) => props.theme.colors.neutral[90]};
`;

import styled from 'styled-components';

export const Container = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.s};
  padding: ${({ theme }) => theme.spacings.s};
  background: ${({ theme }) => theme.colors.neutral[100]};
  border-radius: ${({ theme }) => theme.borders.radius.m};
  border: ${({ theme }) => theme.borders.width.xs2} solid
    ${({ theme }) => theme.colors.neutral[90]};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs2};
`;

export const Company = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs3};
`;

export const Product = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs3};
  align-items: center;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

import React from 'react';
import * as SC from './styled';
import { Icons, IconsProps } from '@flash-tecnologia/hros-web-ui-v2';

export type TabLabelProps = {
  label: React.ReactNode;
  tag?: string | number;
  icon?: IconsProps['name'];
  isDisabled?: boolean;
  isSelected?: boolean;
};

export const TabLabel = ({
  label,
  tag,
  icon,
  isDisabled,
  isSelected,
}: TabLabelProps) => {
  return (
    <SC.TabLabelContainer isSelected={isSelected} isDisabled={isDisabled}>
      <SC.LabelDescriptionContainer>
        {icon && (
          <Icons
            name={icon || ''}
            size={16}
            strokeWidth="1.5"
            fill="transparent"
          />
        )}
        <SC.TabLabel isSelected={isSelected} variant="body4">
          {label}
        </SC.TabLabel>
      </SC.LabelDescriptionContainer>
      {tag && (
        <SC.Counter className="tab-tag" isSelected={isSelected}>
          <SC.CounterLabel variant="body4">{tag}</SC.CounterLabel>
        </SC.Counter>
      )}
    </SC.TabLabelContainer>
  );
};

import { useEffect, useState } from 'react';

export interface UseManagementTabsProps {
  defaultTab: number;
  externalSelectedTab?: number;
  onTabChanged?(currentTab: number): void;
}

export interface UseManagementTabs {
  currentTab: number;
  handleChange(currentTab: number): void;
}

export function useManagementTabs({
  defaultTab,
  externalSelectedTab,
  onTabChanged,
}: UseManagementTabsProps): UseManagementTabs {
  const [currentTab, setSelectedTab] = useState(defaultTab);

  function onExternalSelectedTabIsValid(): boolean {
    return (
      externalSelectedTab != null &&
      externalSelectedTab != undefined &&
      externalSelectedTab != currentTab
    );
  }

  function onSelectedTab(selectedTab: number) {
    setSelectedTab(selectedTab);
    onTabChanged?.(selectedTab);
  }

  function handleChange(selectedTab: number) {
    if (onExternalSelectedTabIsValid()) {
      onSelectedTab(Number(externalSelectedTab));
    } else onSelectedTab(selectedTab);
  }

  useEffect(() => {
    onExternalSelectedTabIsValid() &&
      setSelectedTab(Number(externalSelectedTab));
  }, [externalSelectedTab]);

  return {
    currentTab,
    handleChange,
  };
}

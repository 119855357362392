import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LoadingState } from '@/components';
import { trackPage } from '@/utils/tracking';
import {
  Button,
  Focused,
  IStepperProps,
  Icons,
  LinkButton,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility';
import {
  CommercialConditionsStep,
  ReviewStep,
  SuccessStep,
} from './components';
import { Content } from './contract.styles';
import {
  useActivateSubscription,
  useCreateDeal,
  useGetSubscription,
} from './hooks';
import { useExecuteDeal } from './hooks/use-execute-deal';
import { isTRPCClientError } from '@/api/utils';
import { dispatchToast } from '@/utils';

export interface Module {
  id: string;
  name: string;
  description: string;
}

export interface Subscription {
  id: string;
  contractIds: string[];
  plans: { name: string; id: string; moduleIds: string }[];
  modules: Module[];
}

enum STEPS {
  COMMERCIAL_CONDITIONS = 'Condições comerciais',
  REVIEW = 'Revisão da compra',
  SUCCESS = 'Confirmação da compra',
}

export const ContractPage = () => {
  const [t] = useTranslation('translations', { keyPrefix: 'contract' });
  const navigate = useNavigate();
  const { selectedCompany } = useSelectedCompany();
  const [params] = useSearchParams();
  const queryOfferId = params.get('offerId') ?? '';

  const { createDeal } = useCreateDeal();
  const { executeDeal } = useExecuteDeal();
  const { activateSubscription, isLoading: isLoadingActivateSubscription } =
    useActivateSubscription();
  const { getSubscriptionById } = useGetSubscription();

  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [contractChecks, setContractChecks] = useState<{
    [key: string]: boolean;
  }>({});
  const [subscription, setSubscription] = useState<Subscription>();

  useEffect(() => {
    trackPage('acquisition_contract_page', { offer_id: queryOfferId });
  }, []);

  useEffect(() => {
    async function getSubscription() {
      setIsLoading(true);
      try {
        const subscriptionId = params.get('subscriptionId');
        if (subscriptionId) {
          const subscription = await getSubscriptionById({ subscriptionId });

          if (subscription.status === 'ACTIVE') {
            dispatchToast({ type: 'error', content: 'Assinatura já ativa!' });
            navigate('/home');
            return;
          }

          if (subscription.status === 'CANCELED') {
            dispatchToast({ type: 'error', content: 'Assinatura cancelada!' });
            navigate('/home');
            return;
          }

          setSubscription(subscription);
          return;
        }

        let dealId = params.get('dealId');
        if (!dealId) {
          const deal = await createDeal({
            companyId: selectedCompany.id,
            offerId: queryOfferId,
          });
          dealId = deal.id;
        }

        const subscription = await executeDeal({
          companyId: selectedCompany.id,
          dealId,
        });

        setSubscription(subscription);
      } catch (error) {
        let errorMessage = 'Ocorreu um erro. Por favor, tente novamente.';
        if (isTRPCClientError(error)) {
          errorMessage = error.message;
        }

        dispatchToast({ type: 'error', content: errorMessage });
        navigate('/home');
      } finally {
        setIsLoading(false);
      }
    }

    getSubscription();
  }, []);

  const updateContractChecks = (contractId: string, checked: boolean) => {
    setContractChecks((prev) => ({ ...prev, [contractId]: checked }));
  };

  const stepper: IStepperProps = {
    steps: [STEPS.COMMERCIAL_CONDITIONS, STEPS.REVIEW, STEPS.SUCCESS],
    disableClick: true,
    activeStep: step,
    setActiveStep: setStep,
  };

  const isCurrentStep = (step: STEPS) => {
    return stepper.steps[stepper.activeStep] === step;
  };

  const showBack = isCurrentStep(STEPS.REVIEW);
  const getNextButtonText = () => {
    if (isCurrentStep(STEPS.COMMERCIAL_CONDITIONS))
      return t('commercialConditionsStep.nextText');
    if (isCurrentStep(STEPS.REVIEW)) return t('reviewStep.nextText');
    if (isCurrentStep(STEPS.SUCCESS)) return t('successStep.nextText');
  };

  const onCancel = () => {
    navigate('/home');
  };

  const onBack = () => {
    if (stepper.activeStep !== 0) setStep(stepper.activeStep - 1);
  };

  const onNext = async () => {
    if (isCurrentStep(STEPS.COMMERCIAL_CONDITIONS)) {
      setStep(stepper.activeStep + 1);
      return;
    }

    if (isCurrentStep(STEPS.REVIEW)) {
      if (!subscription) return;
      await activateSubscription({ subscriptionId: subscription.id });
      setStep(stepper.activeStep + 1);
      return;
    }

    if (isCurrentStep(STEPS.SUCCESS)) {
      navigate('/home');
    }
  };

  const plansNames = subscription?.plans.map((p) => p.name) ?? [];

  const allContractsChecked = () => {
    const checkedContracIds = Object.entries(contractChecks)
      .filter(([, value]) => value)
      .map(([key]) => key);
    return subscription?.contractIds.every((id) =>
      checkedContracIds.includes(id),
    );
  };

  if (isLoading || !subscription) {
    return <LoadingState />;
  }

  return (
    <Focused
      containerStyle={{
        // Workaround for hiding menu
        // until refactor
        position: 'fixed',
        width: '100%',
        background: '#fff',
        top: 0,
        left: 0,
      }}
      contentWrapperStyle={{}}
      stepper={stepper}
      // TODO: broken. need to debug on DS
      routes={[]}
      footer={{
        startActions: [
          <LinkButton
            key={1}
            variant="neutral"
            onClick={onCancel}
            disabled={isLoadingActivateSubscription}
          >
            {isCurrentStep(STEPS.SUCCESS) ? t('exitText') : t('cancelText')}
          </LinkButton>,
        ],
        endActions: [
          showBack && (
            <Button
              key={1}
              variant="secondary"
              size="medium"
              onClick={onBack}
              loading={isLoadingActivateSubscription}
            >
              <Icons name="IconArrowLeft" fill="transparent" /> {t('backText')}
            </Button>
          ),
          <Button
            key={2}
            variant="primary"
            size="medium"
            onClick={onNext}
            disabled={!allContractsChecked()}
            loading={isLoadingActivateSubscription}
          >
            {getNextButtonText()}{' '}
            {isCurrentStep(STEPS.SUCCESS) ? null : (
              <Icons name="IconArrowRight" fill="transparent" />
            )}
          </Button>,
        ],
      }}
    >
      <Typography
        variant="headline6"
        variantColor="var(--color-neutral-40)"
        style={{ marginBottom: 40 }}
      >
        {t('title')}
      </Typography>
      <Content>
        {isCurrentStep(STEPS.COMMERCIAL_CONDITIONS) && (
          <CommercialConditionsStep
            contractChecks={contractChecks}
            onContractCheck={updateContractChecks}
            subscription={subscription}
          />
        )}
        {isCurrentStep(STEPS.REVIEW) && <ReviewStep planNames={plansNames} />}
        {isCurrentStep(STEPS.SUCCESS) && <SuccessStep />}
      </Content>
    </Focused>
  );
};

import { Icons as BaseIcons } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.m};
  padding: ${({ theme }) => theme.spacings.s};
  background: ${({ theme }) => theme.colors.secondary[99]};
  border-radius: ${({ theme }) => theme.borders.radius.l};
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  gap: ${({ theme }) => theme.spacings.m};
`;

export const TextsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  gap: ${({ theme }) => theme.spacings.xs3};
`;

export const DetailsContent = styled.div`
  flex-basis: 100%;
`;

export const DetailItem = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs3};
`;

export const LineDecoration = styled.hr`
  width: 20%;
  border-top: 2px solid ${({ theme }) => theme.colors.secondary[50]};
`;

export const OffersInclusion = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs3};
`;

export const OffersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacings.xs3};
`;

export const Icons = styled(BaseIcons)`
  background-color: transparent !important;
`;

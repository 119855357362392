import { ReactElement, ReactNode } from 'react';
import { useManagementTabs } from './hooks/useManagementTabs';
import { TabPrimary } from './TabPrimary';
import { TabSecondary } from './TabSecondary';
import { IconsProps } from '@flash-tecnologia/hros-web-ui-v2';
import { TabTertiary } from './TabTertiary';

export interface TabItens {
  label: string | ReactElement;
  component: ReactNode;
  disabled?: boolean;
  counterLabel?: number;
  iconLabel?: IconsProps['name'];
}

type Variant = 'primary' | 'secondary' | 'tertiary';

export type TabProps = {
  tabItens: TabItens[];
  defaultTab?: number;
  selected?: number;
  variant?: Variant;
  className?: string;
  onTabChanged?: (index: number) => any;
  type?: 'scrollable' | 'standard' | 'fullWidth' | undefined;
};

export const Tab = ({
  tabItens,
  defaultTab = 0,
  className,
  selected,
  variant = 'primary',
  type,
  onTabChanged,
}: TabProps) => {
  const { currentTab, handleChange } = useManagementTabs({
    defaultTab,
    externalSelectedTab: selected,
    onTabChanged,
  });

  if (variant === 'secondary') {
    return (
      <TabSecondary
        className={className}
        handleChange={handleChange}
        tabItems={tabItens}
        currentTab={currentTab}
      />
    );
  }

  if (variant === 'tertiary') {
    return (
      <TabTertiary
        className={className}
        handleChange={handleChange}
        tabItems={tabItens}
        currentTab={currentTab}
      />
    );
  }

  return (
    <TabPrimary
      className={className}
      handleChange={handleChange}
      tabItems={tabItens}
      currentTab={currentTab}
      variant={type}
    />
  );
};

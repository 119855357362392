import React from 'react';

import { TabItens } from '..';

import * as SC from './styled';
import { TabLabel } from './TabLabel';

export type TabProps = {
  tabItems: TabItens[];
  currentTab: number;
  className?: string;
  handleChange: (index: number) => any;
  variant?: 'scrollable' | 'standard' | 'fullWidth' | undefined;
};

export const TabPrimary = ({
  tabItems,
  currentTab,
  className,
  handleChange,
  variant,
}: TabProps) => {
  return (
    <>
      <SC.TabsGroup
        className={`tabs-container ${className}`}
        value={currentTab}
        onChange={(_, currentTab: number) => handleChange(currentTab)}
        variant={variant}
      >
        {tabItems.map((tab, index) => {
          return (
            <SC.TabItem
              disabled={tab.disabled}
              disableRipple={true}
              disableTouchRipple={true}
              key={index}
              value={index}
              label={
                <TabLabel
                  label={tab.label}
                  counter={tab.counterLabel}
                  icon={tab.iconLabel}
                  isDisabled={tab.disabled}
                  isSelected={index == currentTab}
                />
              }
            />
          );
        })}
      </SC.TabsGroup>

      {tabItems[currentTab]?.component || <></>}
    </>
  );
};

import { QueryClient } from '@tanstack/react-query';
import { createTRPCReact, httpBatchLink } from '@trpc/react-query';
import type { AppRouter } from 'bff';
import { getAccessToken } from '@flash-tecnologia/hros-web-utility';
import { inferRouterInputs, inferRouterOutputs } from '@trpc/server';
import { envService } from '@/services/env.service';

export const trpc = createTRPCReact<AppRouter>();

export type RouterInputs = inferRouterInputs<AppRouter>;
export type RouterOutputs = inferRouterOutputs<AppRouter>;

export const queryClient = new QueryClient();
export const trpcClient = trpc.createClient({
  links: [
    httpBatchLink({
      url: envService.BFF_URL,
      headers: async () => {
        const token = await getAccessToken();
        return {
          Authorization: `Bearer ${token}`,
          'x-flash-auth': `Bearer ${token}`,
        };
      },
    }),
  ],
});

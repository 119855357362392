import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { OverviewPage, ComparePage } from '@/pages/Acquisition';
import { ContractPage } from '@/pages/Acquisition/Contract';
import { useRegisterRoutes } from '@flash-tecnologia/hros-web-utility';

const AppRouter = () => {
  useRegisterRoutes([
    {
      hideHeader: true,
      path: '/acquisition/contract',
    },
  ]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="acquisition">
          <Route path="overview" element={<OverviewPage />} />
          <Route path="compare" element={<ComparePage />} />
          <Route path="contract" element={<ContractPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;

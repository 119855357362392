import { createContext, ReactNode, useContext, useState } from 'react';

type OfferInfo = {
  offerId: string;
  planId: string;
};

type ModalContextType = {
  isOpen: boolean;
  openModal: (offerId: string, plan?: string) => void;
  closeModal: () => void;
  offerInfo?: Partial<OfferInfo>;
};

const ModalContext = createContext<ModalContextType | undefined>(undefined);

type ProviderProps = { children: ReactNode };

export const SalesContactModalProvider = ({ children }: ProviderProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [offerInfo, setOfferInfo] = useState<Partial<OfferInfo>>();

  const openModal = (offerId: string, planId?: string) => {
    setOfferInfo({ offerId, planId });
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setOfferInfo({});
  };

  return (
    <ModalContext.Provider value={{ isOpen, openModal, closeModal, offerInfo }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useSalesContactModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error(
      'useSalesContactModal must be used within a SalesContactModalProvider',
    );
  }
  return context;
};

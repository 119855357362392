import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs};
`;

export const LeftContent = styled.div`
  max-width: 320px;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs2};
`;

export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

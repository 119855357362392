import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: auto;
  align-self: flex-start;
  border-radius: ${({ theme }) => theme.borders.radius.m};
  border: ${({ theme }) => theme.borders.width.xs2} solid
    ${({ theme }) => theme.colors.neutral[90]};
  margin-bottom: ${({ theme }) => theme.spacings.s};
  padding: ${({ theme }) => theme.spacings.xs5};
`;

export const ContractItemContainer = styled.div<{ isSelected: boolean }>`
  display: flex;
  border-radius: ${({ theme }) => theme.borders.radius.m};
  border: ${({ theme, isSelected }) =>
    isSelected
      ? `${theme.borders.width.xs2} ${theme.colors.secondary[70]} solid`
      : 'none'};
  background-color: ${({ theme, isSelected }) =>
    isSelected ? `${theme.colors.secondary[95]}` : 'none'};
  padding: ${({ theme }) => theme.spacings.xs4}
    ${({ theme }) => theme.spacings.xs3};
  gap: ${({ theme }) => theme.spacings.xs5};
  transition: background-color 0.2s ease-in-out;
  &:hover {
    cursor: pointer;
  }
`;

import { PageContainer } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const StyledPageContainer = styled(PageContainer)`
  && {
    gap: ${({ theme }) => theme.spacings.m};
    margin-top: ${({ theme }) => theme.spacings.m};
    padding-bottom: ${({ theme }) => theme.spacings.xl4};
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.l};
`;

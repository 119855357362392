import { trpc } from '@/api/client';
import { dispatchToast } from '@/utils';

export const useCreateDeal = () => {
  const { mutateAsync, isLoading, error } = trpc.createDeal.useMutation({
    onError: (error) => {
      if (error.data?.code === 'OFFER_ALREADY_CONTRACTED') {
        dispatchToast({
          type: 'error',
          content: 'Oferta já contratada!',
        });
      }
    },
  });
  return { createDeal: mutateAsync, isLoading, error };
};

import { Skeleton, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { Container, Content } from './resources-section.styles';
import { Tab } from '@/components/ds-overrides/Tab';
import { TabLabel } from './components/TabLabel';
import { Resource } from './components/Resource';
import { useTranslation } from 'react-i18next';
import { trackEvent } from '@/utils/tracking';
import { useGetMarketPage } from '@/shared/hooks';

interface ResourcesSectionProps {
  marketPageId: string;
  showOffersIncludedInfo?: boolean;
}

export const ResourcesSection = ({
  marketPageId,
  showOffersIncludedInfo = false,
}: ResourcesSectionProps) => {
  const [t] = useTranslation('translations', {
    keyPrefix: 'acquisition',
  });
  const { marketPage, isLoading } = useGetMarketPage(marketPageId);

  if (isLoading) {
    return <Skeleton variant="rounded" width="100%" height="300px" />;
  }

  if (!marketPage || marketPage.resources.length === 0) {
    return null;
  }

  const offersNameTuple = marketPage.offers.map((offer) => {
    return [offer.id, offer.name];
  });

  const resources = marketPage.resources.map((resource) => {
    const includedInOffers: string[] = [];
    const notIncludedInOffers: string[] = [];

    if (showOffersIncludedInfo) {
      offersNameTuple.forEach(([offerId, offerName]) => {
        if (resource.offerIds.includes(offerId))
          includedInOffers.push(offerName);
        else notIncludedInOffers.push(offerName);
      });
    }

    return {
      ...resource,
      offers: {
        included: includedInOffers,
        notIncluded: notIncludedInOffers,
      },
    };
  });

  const onTabChanged = (tabIndex: number) => {
    trackEvent('acquisition_resources_feature_clicked', {
      resource_name: resources[tabIndex]?.name,
    });
  };

  return (
    <Container id="offer-resources">
      <Typography variant="headline6" style={{ marginBottom: 40 }}>
        {t('resourcesSection.title')}
      </Typography>
      <Content>
        <Tab
          variant="secondary"
          type="fullWidth"
          onTabChanged={onTabChanged}
          tabItens={resources.map((resource) => ({
            label: (
              <TabLabel
                icon={resource.icon}
                name={resource.name}
                tag={resource.badgeText}
              />
            ),
            component: (
              <Resource
                name={resource.name}
                description={resource.description}
                details={resource.details}
                previewImagePath={resource.previewImageUrl}
                offers={resource.offers}
                showOffersIncludedInfo={showOffersIncludedInfo}
              />
            ),
          }))}
        />
      </Content>
    </Container>
  );
};
